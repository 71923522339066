var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"pa-4 board-card"},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"mb-3"},[_c('router-link',{staticClass:"board-card__title",attrs:{"to":{ name: 'board', params: { id: _vm.board.id } }}},[_vm._v(_vm._s(_vm.board.name))]),_c('div',{staticClass:"mt-1"},[(_vm.requestName)?_c('div',[_c('v-icon',[_vm._v("mdi-database")]),_vm._v(" "+_vm._s(_vm.requestName)+" ")],1):_vm._e(),(_vm.countOfIds)?_c('div',[_c('v-icon',[_vm._v("mdi-playlist-check")]),_vm._v(" Список ID ("+_vm._s(_vm.board.talent_ids.length)+" "+_vm._s(_vm.countOfIds)+"). "),_c('a',{staticClass:"board-card__control",attrs:{"href":"#","title":"Скачать список"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleExport.apply(null, arguments)}}},[_vm._v("Скачать")])],1):_vm._e()])],1),_c('div',{staticClass:"spacer"}),_c('v-btn',{attrs:{"title":"Удалить воронку","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('delete', _vm.board)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),(_vm.board.tags && _vm.board.tags.length)?_c('div',{staticClass:"mb-3"},_vm._l((_vm.board.tags),function(item){return _c('v-chip',{key:item.id,staticClass:"mr-2 mb-2",attrs:{"color":item.color,"dark":"","small":"","title":`Показать всех пользователей с тегом «${item.name}»`},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
          name: 'search',
          query: {
            tags_ids: item.id,
            search: '',
          },
        })}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e(),_c('v-divider'),_c('div',{staticClass:"d-flex align-start mt-3"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.board.author)?_c('div',[_c('v-avatar',{attrs:{"color":"#dedede","darken":"","size":"22","elevation":"1"}},[(_vm.board.author.avatar)?_c('img',{attrs:{"src":_vm.board.author.avatar}}):_c('v-icon',{attrs:{"color":"white","size":"16"}},[_vm._v("mdi-account")])],1),_vm._v(" "+_vm._s(_vm.board.author.first_name)+" "+_vm._s(_vm.board.author.last_name)+" ")],1):_vm._e()]),(_vm.lastUpdate)?_c('div',[_vm._v("Обновлено: "+_vm._s(_vm.lastUpdate))]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }