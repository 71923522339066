<template>
  <v-sheet class="pa-4 board-card">
    <div class="d-flex align-start">
      <div class="mb-3">
        <router-link
          class="board-card__title"
          :to="{ name: 'board', params: { id: board.id } }"
          >{{ board.name }}</router-link
        >
        <div class="mt-1">
          <div v-if="requestName">
            <v-icon>mdi-database</v-icon> {{ requestName }}
          </div>
          <div v-if="countOfIds">
            <v-icon>mdi-playlist-check</v-icon>
            Список ID ({{ board.talent_ids.length }} {{ countOfIds }}).
            <a
              href="#"
              title="Скачать список"
              class="board-card__control"
              @click.stop.prevent="handleExport"
              >Скачать</a
            >
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <v-btn
        title="Удалить воронку"
        icon
        color="primary"
        @click="$emit('delete', board)"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </div>
    <div v-if="board.tags && board.tags.length" class="mb-3">
      <v-chip
        v-for="item in board.tags"
        :key="item.id"
        :color="item.color"
        class="mr-2 mb-2"
        dark
        small
        :title="`Показать всех пользователей с тегом «${item.name}»`"
        @click.stop="
          $router.push({
            name: 'search',
            query: {
              tags_ids: item.id,
              search: '',
            },
          })
        "
      >
        {{ item.name }}
      </v-chip>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-start mt-3">
      <div class="flex-grow-1">
        <div v-if="board.author">
          <v-avatar color="#dedede" darken size="22" elevation="1">
            <img v-if="board.author.avatar" :src="board.author.avatar" />
            <v-icon v-else color="white" size="16">mdi-account</v-icon>
          </v-avatar>
          {{ board.author.first_name }}
          {{ board.author.last_name }}
        </div>
      </div>
      <div v-if="lastUpdate">Обновлено: {{ lastUpdate }}</div>
    </div>
  </v-sheet>
</template>

<script>
import { numCases } from "@/utils";
import { downloadSheet } from "@/utils/xlsx";
import dayjs from "@/plugins/dayjs";
export default {
  name: "BoardCard",
  props: {
    board: {
      type: Object,
    },
    lastSync: {
      type: String,
      default: "",
    },
    /**
     * sql request model
     */
    request: {
      type: Object,
    },
  },
  computed: {
    /**
     * Пока скрываю, так как она не правильная
     */
    lastUpdate() {
      const { lastSync } = this;
      // const date = board?.update_at ?? board?.created_at;
      if (!lastSync) return;
      /**
       * Проблема с датой на сервере, она почемуто-то отстает периодически
       */
      if (dayjs(lastSync) > dayjs()) {
        return "только что";
      }
      return dayjs(lastSync).fromNow();
    },

    countOfIds() {
      const { board } = this;
      if (!board.talent_ids?.length) return;
      return numCases(["запись", "записи", "записей"], board.talent_ids.length);
    },
    requestName() {
      const { request, board } = this;
      if (!board?.talent_query_id) return;
      return request?.name ?? `SQL Запрос ${board.talent_query_id}`;
    },
  },
  methods: {
    async handleExport() {
      if (!this.board.talent_ids?.length) {
        this.$toast("Список talent_id пользователей пуст!", {
          type: "error",
        });
        return;
      }
      const list = [...this.board.talent_ids].sort((a, b) => {
        return a - b;
      });
      try {
        await downloadSheet(
          ["Talent ID"],
          list.map((id) => [id]),
          `Список пользователей воронки ${this.board?.name}.`,
          "Список ID пользователей"
        );
      } catch (error) {
        let msg = "не удалось скачать таблицу.";
        if (error?.message) {
          msg = `${msg} ${error.message}.`;
        }
        this.$toast(msg, { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
.board-card {
  position: relative;

  &__control {
    position: relative;
    z-index: 1;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: inherit !important;
    transition: color 0.3s;
    max-width: 600px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: var(--v-anchor-base) !important;
    }
  }
}
</style>
