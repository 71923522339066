<template>
  <v-container class="home">
    <div class="d-flex mb-3">
      <h1>Воронки</h1>
      <div class="spacer"></div>
    </div>
    <div class="d-sm-flex d-xs-block">
      <div class="mr-sm-4 mr-xs-0">
        <div class="home__container">
          <v-btn
            width="100%"
            color="primary"
            :to="{ name: 'boards_create' }"
            class="mb-4"
            ><v-icon left>mdi-plus-box</v-icon>Создать воронку</v-btn
          >
          <v-card>
            <v-card-title class="text-h6 rm-dialog-title pb-0"
              >Фильтры</v-card-title
            >
            <v-form @submit.prevent="handleSearch"
              ><v-card-text class="pt-1 pb-1">
                <v-text-field
                  v-model.trim="filters.search"
                  label="Название"
                  clearable
                />
                <v-checkbox
                  v-model="filters.author_id"
                  label="Созданные мной"
                  color="primary"
                  :value="String(user.id)"
                  class="mt-2"
                />
              </v-card-text>

              <v-card-actions class="d-block">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="d-block mb-2"
                  outlined
                  width="100%"
                  type="submit"
                >
                  Искать
                </v-btn>
                <v-btn
                  elevation="0"
                  outlined
                  color="secondary"
                  class="d-block ma-0"
                  width="100%"
                  :disabled="isFiltersEmpty"
                  @click="handleClearFilters"
                  >Сбросить фильтры</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </div>

      <div class="flex-grow-1">
        <div>
          <div style="min-height: 4px">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
              :active="boards.pending"
            ></v-progress-linear>
          </div>
          <board-card
            v-for="board in list"
            :key="board.id"
            :board="board"
            :request="sqlRequests[board.talent_query_id]"
            :last-sync="syncs[board.id]"
            class="mb-3"
            @delete="handleDelete"
          >
          </board-card>
        </div>

        <v-pagination
          v-if="pagesCount > 1"
          :total-visible="7"
          :length="pagesCount"
          :value="boards.page"
          @input="setPage"
        ></v-pagination>

        <div v-if="showClearFiltersMessage">
          Воронок по выбранным параметрам нет.
          <a
            href="#"
            class="link link--pseudo"
            @click.prevent="handleClearFilters"
            >Сбросить фильтры</a
          >
        </div>

        <div v-if="error" class="error--text">{{ error }}</div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { apiClient, talentClient } from "@/api";
import BoardCard from "@/components/BoardCard.vue";
import { isEqual } from "lodash";
import { TASK_STATUS_COMPLETED } from "@/constants";

const initialFilters = () => {
  return {
    author_id: "",
    search: "",
  };
};
export default {
  name: "Boards",
  components: {
    BoardCard,
  },
  data() {
    return {
      boards: {
        page: 1,
        size: 10,
        total: 0,
        list: [],
        pending: false,
        searchName: "",
      },
      filters: initialFilters(),
      error: null,
      syncs: {},
      sqlRequests: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    talentUsers() {
      return this.$store.state.talent.users;
    },
    pagesCount() {
      return Math.ceil(this.boards.total / this.boards.size);
    },
    list() {
      return this.boards.list.map((board) => {
        const { ...rest } = board;
        const result = {
          ...rest,
        };
        if (result.author_id === this.user.id) {
          result.author = { ...this.user };
        } else {
          result.author = this.talentUsers[result.author_id];
        }
        return result;
      });
    },
    searchParams() {
      const { filters } = this;
      return Object.keys(filters).reduce((acc, key) => {
        if (filters[key]) {
          acc[key] = filters[key];
        }
        return acc;
      }, {});
    },
    isFiltersEmpty() {
      const { filters } = this;
      return !Object.keys(filters).some((key) => {
        return filters[key];
      });
    },
    showClearFiltersMessage() {
      const { boards } = this;
      return (
        !boards.pending &&
        !boards.list.length &&
        !boards.error &&
        !this.isFiltersEmpty
      );
    },
  },
  watch: {
    "$route.query": {
      handler() {
        this.handleFetchBoards();
      },
    },
  },
  created() {
    this.setQueryToFilters();
    this.getBoards();
    this.$store.dispatch("talent/getUsers", [44, 55, 66, 77]);
  },
  methods: {
    getAuthors() {
      if (!this.boards.list.length) return;
      const uniqIds = this.boards.list.reduce((acc, board) => {
        if (!acc[board.author_id] && board.author_id !== this.user.id) {
          acc[board.author_id] = board.author_id;
        }
        return acc;
      }, {});
      const requestIds = Object.values(uniqIds);
      this.$store.dispatch("talent/getUsers", requestIds);
    },
    async getBoards() {
      try {
        const { searchParams } = this;
        const { page, size } = this.boards;
        const offset = (page - 1) * size;
        this.error = null;
        this.boards.pending = true;
        const { data } = await apiClient({
          method: "GET",
          url: "/boards",
          params: {
            offset,
            limit: size,
            ...searchParams,
          },
        });
        this.boards.list = data.results;
        this.boards.total = data.count;
        /**
         * Запросим метаданные по текущим воронкам из таланта.
         * (данные о авторе, данные о квери)
         */
        this.getAuthors();
        this.getListSyncs();
        this.getListSQLrequests();
      } catch (error) {
        this.error = error.message;
      }
      this.boards.pending = false;
    },
    /**
     * Обработчик события удаления доски
     * @param {board} object = доска
     */
    async handleDelete(board) {
      // сначала вызываем конфирм
      const confirm = await this.$root.$confirm(
        "Удаление воронки",
        `Вы действительно хотите удалить воронку <strong>&laquo;${board.name}&raquo;</strong>`,
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (confirm) {
        try {
          await apiClient({
            method: "DELETE",
            url: `/boards/${board.id}`,
          });
          const idx = this.boards.list.findIndex((n) => n.id === board.id);
          if (idx >= 0) {
            this.$delete(this.boards.list, idx);
          }
        } catch (error) {
          this._showError(`Не удалось удалить воронку. ${error.message}`);
        }
      }
    },
    setQueryToFilters() {
      const { query } = this.$route;
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = query[key];
      });
      this.boards.page = query.page ? +query.page : 1;
    },
    setPage(page) {
      this.boards.page = page;
      this.setQuery();
    },
    setQuery() {
      const { searchParams } = this;
      const query = { ...searchParams, page: String(this.boards.page) };
      if (!isEqual(query, this.$route.query)) {
        this.$router.push({
          query,
        });
      }
    },
    async handleFetchBoards() {
      this.setQueryToFilters();
      await this.getBoards();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleSearch() {
      this.boards.page = 1;
      this.setQuery();
    },
    handleClearFilters() {
      this.boards.page = 1;
      this.filters = initialFilters();
      this.setQuery();
    },

    async getListSyncs() {
      const { list } = this.boards;
      if (!list?.length) return;
      const reqs = list.map(({ id }) => {
        return apiClient({
          method: "GET",
          url: `/boards/${id}/tasks`,
          params: {
            limit: 5,
            offset: 0,
          },
        });
      });
      const syncs = (await Promise.all(reqs)).reduce((acc, value) => {
        const result = value.data?.results?.find((n) => {
          return n.status === TASK_STATUS_COMPLETED;
        });
        console.log("n.status", result);
        if (result) {
          acc[result.board_id] = result.created_at;
        }
        return acc;
      }, {});

      this.syncs = syncs;
    },
    async getListSQLrequests() {
      const { sqlRequests } = this;
      const { list } = this.boards;
      if (!list?.length) return;
      let queries = list.reduce((acc, { talent_query_id }) => {
        if (
          !!talent_query_id &&
          !sqlRequests[talent_query_id] &&
          !acc.includes(talent_query_id)
        ) {
          acc.push(talent_query_id);
        }
        return acc;
      }, []);

      const { data } = await talentClient({
        method: "GET",
        url: "/talent-sql-queries/",
        params: {
          ids: queries.join(","),
          limit: queries.length,
          offset: 0,
        },
      });

      if (data.results?.length) {
        this.sqlRequests = {
          ...this.sqlRequests,
          ...data.results.reduce((acc, value) => {
            acc[value.id] = value;
            return acc;
          }, {}),
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home__container {
  position: sticky !important;
  top: 60px;
  z-index: 1;
  width: 250px;
  margin-top: 4px;

  @media screen and (max-width: 601px) {
    width: 100%;
  }
}
</style>
