/**
 * Создает и скачивает эксель табличку
 * @param {(string|number)[]} head - шапка таблицы
 * @param {(string|number)[]} body - тело таблицы
 * @param {string} docName - Название документа
 * @param {string=} sheetName - Название листа с таблицей
 */
export const downloadSheet = (head, body, docName, sheetName = "default") => {
  const XLSX = window?.XLSX;
  if (!XLSX) {
    throw new Error("Плагин для работы с таблицами не инициализирован");
  }
  if (!docName && typeof docName !== "string") {
    throw new Error("Не передан обязательный параметр: Название документа");
  }
  if (!Array.isArray(head) || !head?.length) {
    throw new Error("Шапка таблицы не может быть пустой");
  }
  if (!Array.isArray(body) || !body?.length) {
    throw new Error("тело таблицы не может быть пустым");
  }

  const ws = XLSX.utils.aoa_to_sheet([head, ...body]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${docName}.xlsx`);
};
